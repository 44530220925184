@import "../../variables";

.input {
  font-family: inherit;
  font-size: $text-small;

  border: 1px solid $border-color-dark;

  padding-left: $padding-smallest;
  width: 100%;
  height: 4rem;

  background: inherit;
  box-shadow: 0 2px 10px $color2-light;

  &:focus {
    outline: none;
    box-shadow: 1px 1px 10px $color2-mid;
  }

  &::placeholder {
    color: $text-color;

    opacity: 0.5;
  }

  // for number input arrows to disappear
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
}