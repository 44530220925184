@import "../../variables";
@import "../../mixins";

$show-page-form-width: 45rem;
$show-page-form-container-width: 50%;

.show-post {
  &__image-wrapper {
    overflow: hidden;
    @include initial-image-container;
    max-height: 50rem;
  }

  &__image {
    position: absolute;

    filter: opacity(90%) blur(2px);

    @include image-sizing;
    @include center-absolute;
  }

  &__content-wrapper {
    padding: $padding-small $padding-largest;
    margin-bottom: $margin-largest;

    @media (max-width: $breakpoint-phone) {
      padding: $padding-small $padding-smallest;
    }
  }

  &__content-subject {
    font-size: $header-medium;
    letter-spacing: $letter-spacing-mid;

    margin-bottom: $margin-small;
    padding: 0 0 $padding-smallest $padding-small;
    border-bottom: 1px solid $color1-mid;
  }

  &__content-content {

  }

  &__contact-container {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: $margin-large;
    padding: $padding-smallest $padding-small;
  }

  &__contact-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    flex-basis: $show-page-form-container-width;

    padding-right: $padding-small;
    border-right: 1px solid $color2-mid;

    @media (max-width: $breakpoint-tablet) {
      justify-content: center;
      flex-basis: 100%;

      padding-right: 0;
      border-right: none;
    }
  }

  &__contact-header {
    font-size: $header-small;
    letter-spacing: $letter-spacing-small;
    color: $color1-mid;
    text-align: end;

    margin-bottom: $margin-small;
    border-bottom: 1px solid $color1-mid;
    padding: 0 $padding-small $padding-smallest 0;

    max-width: $show-page-form-width;
    width: 100%;

    @media (max-width: $breakpoint-tablet) {
      text-align: center;
    }
  }

  &__contact-form {
    margin-bottom: $margin-small;

    max-width: $show-page-form-width;
    width: 100%;
  }

  &__form-button-wrapper {
    flex-basis: 100%;
    text-align: end;

    @media (max-width: $breakpoint-tablet) {
      text-align: center;
    }
  }

  &__logo-container {
    display: grid;

    flex-basis: calc(100% - #{$show-page-form-container-width});
  }

  &__logo-wrapper {
    margin: auto $margin-largest;
  }

  &__logo {
    width: 100%;
    max-width: 25rem;
    height: auto;
  }
}