@import "./mixins.scss";
@import "./keyframes.scss";
@import "./mixins.scss";

/**
 * GENERAL CLASSES
 */
.initial-page {
  width: 100%;
  min-height: calc(100vh - #{$header-height});
}

.coming-soon {
  display: grid;

  &__text {
    font-size: $header-medium;
    color: $color1-mid;
    margin:auto;

    @media (max-width: $breakpoint-phone) {
      font-size: $text-large
    }
  }
}

.flex-basis-100 {
  flex-basis: 100%;
}

/**
 * OUTER COMPONENTS
 */
.i18n-repo {
  &__action {
    display: inline;
    word-break: break-word;
    @include link
  }

  &__content {
    display: inline;
    word-break: break-word;
  }
}

/**
 * CONTAINERS
 */
@import "./component-classes/containers/AboutUs";
@import "./component-classes/containers/Contact";
@import "./component-classes/containers/Insights";
@import "./component-classes/containers/LandingPage";
@import "./component-classes/containers/Service";
@import "./component-classes/containers/ShowPost";

/**
 * ELEMENTS
 */
@import "component-classes/elements/CookieAccepted";
@import "component-classes/elements/Disclaimer";
@import "component-classes/elements/Footer";
@import "component-classes/elements/Header";
@import "component-classes/elements/PrivacyPolicy";

/**
 * UI-KIT
 */
@import "component-classes/ui-kit/Arrow";
@import "component-classes/ui-kit/ArrowButton";
@import "component-classes/ui-kit/Button";
@import "component-classes/ui-kit/Form";
@import "component-classes/ui-kit/Input";
@import "component-classes/ui-kit/Layout";
@import "component-classes/ui-kit/Loader";
@import "component-classes/ui-kit/MessageGate";
@import "component-classes/ui-kit/Modal";
@import "component-classes/ui-kit/QuillEditor";
@import "component-classes/ui-kit/Spinner";
@import "component-classes/ui-kit/Textarea";
@import "component-classes/ui-kit/Upload";
