@import "../../variables";
@import "../../mixins";

$image-width-large: 55rem;
$image-width-medium: 40rem;
$image-width-small: 30rem;

.service {
  position: relative;

  &__menu-container {
    display: flex;
    justify-content: center;

    margin-right: $image-width-large;
    padding: $padding-large 0;

    width: calc(100% - #{$image-width-large});
    max-height: calc(100vh - #{$header-height});

    overflow: scroll;

    @include style-scrollbar;

    @media (max-width: $breakpoint-desktop) {
      margin-right: $image-width-medium;
      width: calc(100% - #{$image-width-medium});
    }

    @media (max-width: $breakpoint-tablet) {
      margin-right: 0;
      width: 100%;
    }
  }

  &__menu-wrapper {
    max-width: 55rem;
    width: 100%;

    pading-bottom: $padding-largest;
  }

  &__menu-item {
    display: flex;
    align-items: center;

    padding: $margin-smallest $margin-smallest $margin-small;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: $color2-lightest;

    transition: all $time-less;

    @media (hover: hover) {
      &:hover {
        box-shadow: 10px 0px 10px -7px $border-color-dark,
        -10px 0px 10px -7px $border-color-dark;
      }
    }
  }

  &__menu-item-content-wrapper {
    width: calc(100% - 5rem);
    padding-right: 2rem;
  }

  &__menu-item-header {
    font-size: $text-large;
    color: $color1-light;
    letter-spacing: $letter-spacing-small;

    margin-bottom: $margin-smallest;
    padding-bottom: $padding-smallest;
    border-bottom: 1px solid $color1-light;
  }

  &__menu-item-description {
    font-size: $text-small;
  }

  &__panel {
    position: absolute;
    top: 0;

    display: flex;
    justify-content: center;

    height: calc(100vh - #{$header-height});
    width: $image-width-large;
    filter: opacity(50%);

    overflow: hidden;
    box-shadow: 10px 0px 20px -7px $color2-dark,
    -10px 0px 20px -7px $color2-dark;

    @media (max-width: $breakpoint-desktop) {
      width: $image-width-medium;
    }

    &__right {
      right: 0;
    }

    &__animate-right {
      right: 0;
      animation: service-image-right $time-less;
    }

    &__left {
      left: 0;
    }

    &__animate-left {
      left: 0;
      animation: service-image-left $time-less;
    }
  }

  &__image {
    @include image-sizing;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin-left: $image-width-large;

    width: calc(100% - #{$image-width-large});
    height: calc(100vh - #{$header-height});

    overflow: scroll;

    @include style-scrollbar;

    @media (max-width: $breakpoint-desktop) {
      margin-left: $image-width-medium;
      width: calc(100% - #{$image-width-medium});
    }

    @media (max-width: $breakpoint-tablet) {
      margin-left: 0;
      width: 100%;
    }
  }

  &__content-header {
    flex-basis: 100%;

    height: 5rem;

    margin-bottom: $margin-small;
    padding: $padding-smallest $padding-small
  }

  &__content-wrapper {
    flex-basis: 100%;

    padding: $padding-small $padding-smallest;

    max-width: 65rem;
  }

  &__content-text-wrapper {

  }

  &__content-text-header {
    font-size: $header-small;
    letter-spacing: $letter-spacing-small;
    color: $color1-light;

    padding-bottom: $padding-smallest;
    border-bottom: 1px solid $color1-light;
    margin-bottom: $margin-smallest;
  }

  &__content-text {
    font-size: $text-medium;
    letter-spacing: $letter-spacing-small;
    text-align: justify;

    margin-bottom: $margin-smallest;

    @media (max-width: $breakpoint-phone) {
      font-size: $text-small;
      word-break: break-word;
    }
  }

  &__unordered-list {
    font-size: $text-medium;
    padding-left: $padding-small;
    margin-bottom: $margin-smallest;

    @media (max-width: $breakpoint-phone) {
      font-size: $text-small;
      word-break: break-word;
    }
  }

  &__unordered-list-item {
    text-align: justify;
    font-size: $text-medium;
    letter-spacing: $letter-spacing-small;
    margin-bottom: $margin-smallest;

    &__header {
      font-weight: bold;
    }

    @media (max-width: $breakpoint-phone) {
      font-size: $text-small;
      word-break: break-word;
    }
  }

  &__content-text-highlight {
    font-size: $text-medium;
    font-weight: bold;
  }

  &__menu-animation {
    animation: service-menu-animation $time-less;
  }

  &__content-animation {
    animation: service-content-animation $time-less;
  }
}

@keyframes service-menu-animation {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes service-content-animation {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes service-image-left {
  from {
    left: calc(100% - #{$image-width-large});

    @media (max-width: $breakpoint-desktop) {
      left: calc(100% - #{$image-width-medium});
    }
  }
  to {
    left: 0
  }
}

@keyframes service-image-right {
  from {
    right: calc(100% - #{$image-width-large});

    @media (max-width: $breakpoint-desktop) {
      right: calc(100% - #{$image-width-medium});
    }
  }
  to {
    right: 0;
  }
}