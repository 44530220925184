@import "../../variables";
@import "../../mixins";

.upload {
  &__container {

  }

  &__input {
    display: none;
  }

  &__button {
    margin-bottom: $margin-smallest;
    padding: $padding-smallest $padding-small;
  }

  &__button-text-container {
    display: flex;
    flex-wrap: wrap;
  }

  &__button-icon {
    margin-right: $margin-smallest;
  }

  &__button-text {
    font-size: $text-medium;
  }

  &__file-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    font-size: $text-medium;

    padding: $padding-smallest $padding-small;
    border: 1px solid $color2-mid;

    width: 100%;

    border-radius: $round-radius;
  }

  &__file-name {
    @include no-select;
  }

  &__times-icon {
    cursor: pointer;
    transition: all 0.3s;

    @media (hover: hover) {
      &:hover {
        color: $color1-mid;
      }
    }
  }
}