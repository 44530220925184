@import "../../variables";
@import "../../mixins";

.insights {
  display: flex;
  flex-wrap: wrap;

  &__content-container {
    display: grid;
    grid-template-columns: minmax(28rem, 1fr) minmax(28rem, 1fr) minmax(28rem, 1fr);
    gap: 4rem;
    flex-basis: 100%;

    padding: $padding-mid $padding-large;
    margin-bottom: $margin-mid;

    @media (max-width: $breakpoint-desktop) {
      grid-template-columns: minmax(28rem, 1fr) minmax(28rem, 1fr);
      gap: 3rem;
      padding: $padding-mid $padding-mid;
    }

    @media (max-width: $breakpoint-tablet) {
      grid-template-columns: minmax(28rem, 1fr);
      gap: 5rem;
      padding: $padding-mid $padding-mid;
    }

    @media (max-width: $breakpoint-phone) {
      padding: $padding-mid $padding-smallest;
      grid-template-columns: minmax(25rem, 1fr);
    }
  }

  &__content {
    justify-self: center;
    width: 100%;
    max-width: 40rem;

    border: 1px solid $color2-mid;

    height: 55rem;

    cursor: pointer;
    transition: all $time-less;
    overflow: hidden;
    border-radius: $edgeless-radius;
    box-shadow: 10px 10px 20px $color2-mid;

    @include no-select;

    @media (hover: hover) {
      &:hover {
        transform: translateY(-5px);
      }
    }

    @media (max-width: $breakpoint-tablet) {
      max-width: 60rem;
    }
  }

  &__image-wrapper {
    position: relative;

    height: 25rem;
    width: 100%;
  }

  &__image {
    position: absolute;
    filter: blur(2px);
    @include image-sizing;
    @include center-absolute;
  }

  &__content-text-wrapper {
    position: relative;

    padding: 0 $padding-smallest;

    width: 100%;
    height: 30rem;
    overflow: hidden;
  }

  &__content-subject {
    font-size: $text-large;
    letter-spacing: $letter-spacing-small;
    border-bottom: 1px solid $color1-mid;
    color: $color1-mid;

    padding: $padding-smallest $padding-small;
    margin-bottom: $margin-smallest;
  }

  &__content-content {

  }

  &__content-content-blur {
    position: absolute;
    bottom: 0;
    left: 0;

    height: 100%;
    width: 100%;

    background-image: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 70%);
  }

  &__button-wrapper {
    flex-basis: 100%;
    text-align: center;

    margin-bottom: $margin-mid;
  }
}