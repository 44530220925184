@import "../../variables";
@import "../../mixins";

.about-us {
  &__image-wrapper {
    overflow: hidden;
    @include initial-image-container;
    max-height: 50rem;
  }

  &__image {
    position: absolute;

    filter: opacity(50%);

    @include image-sizing;
    @include center-absolute;
  }

  &__section-container {
  }

  &__section-wrapper {
    display: flex;
    flex-wrap: wrap;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color-dark;
    }
  }

  &__section-image-wrapper {
    flex-basis: 40%;
    position: relative;
    overflow: hidden;
  }

  &__section-image {
    position: absolute;

    filter: opacity(50%);

    @include image-sizing;
    @include center-absolute;
  }

  &__content-wrapper {
    flex-basis: 60%;
    padding: $padding-large $padding-small;

    box-shadow: 10px 0px 10px -7px $border-color-dark,
                -10px 0px 10px -7px $border-color-dark;

    @media (max-width: $breakpoint-tablet) {
      flex-basis: 100%;
      width: 100%;
      max-width: 60rem;

      box-shadow: none;
    }
  }

  &__section-header-left {
    font-size: $header-small;
    letter-spacing: $letter-spacing-mid;
    color: $color1-mid;
    text-align: start;

    padding: 0 0 $padding-smallest $padding-small;
    border-bottom: 1px solid $color1-mid;

    @media (max-width: $breakpoint-phone) {
      padding: 0 0 $padding-smallest 0;
      text-align: center;
    }
  }

  &__header-wrapper {
    width: 100%;

    margin-bottom: $margin-mid;
  }

  &__section-header-right {
    font-size: $header-small;
    letter-spacing: $letter-spacing-small;
    color: $color1-mid;
    text-align: end;

    padding: 0 $padding-small $padding-smallest 0;
    border-bottom: 1px solid $color1-mid;

    @media (max-width: $breakpoint-tablet) {
      text-align: start;
    }

    @media (max-width: $breakpoint-phone) {
      padding: 0 $padding-smallest 0 0;
      text-align: center;
    }
  }

  &__section-content {
    flex-basis: 100%;
  }

  &__text-wrapper {

  }

  &__text {
    font-size: $text-medium;
    letter-spacing: $letter-spacing-small;
    text-align: justify;

    margin-bottom: $margin-smallest;

    @media (max-width: $breakpoint-phone) {
      font-size: $text-small;
    }
  }

  &__left {
    justify-content: flex-start;
    text-align: start;

    @media (max-width: $breakpoint-tablet) {
      justify-content: center;
      text-align: start;
    }
  }

  &__right {
    justify-content: flex-start;
    text-align: start;

    background-color: $color2-light;

    @media (max-width: $breakpoint-tablet) {
      justify-content: center;
      text-align: start;
    }
  }
}