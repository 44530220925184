@import "../../variables";

.privacy-policy {
  text-align: justify;

  &__section {
    margin-bottom: $margin-mid;
  }

  &__header {
    font-size: $header-small;
    font-weight: bold;
    font-family: $header-font;
    text-align: center;

    margin-bottom: $margin-small;

    width: 100%;
  }

  &__section-header {
    font-size: $text-medium;
    font-weight: bold;
    margin-bottom: $margin-smallest;
  }

  &__sub-header {
    font-size: $text-small;
    font-weight: bold;
    margin-bottom: $margin-smallest;
  }

  &__text {
    font-size: $text-small;
    margin-bottom: $margin-smallest;
  }

  &__unordered-list-item {
    font-size: $text-small;
  }

  &__unordered-list {
    padding-left: $padding-small;
    margin-bottom: $margin-smallest;
  }
}