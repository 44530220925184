@import "../../variables";
@import "../../mixins";

$width-without-scrollbar: calc(100vw - #{$scroll-width});

.landing-page {
  &__images-container {
    @include initial-image-container
  }

  &__images-wrapper {
    display: grid;
    grid-template-columns: $width-without-scrollbar $width-without-scrollbar $width-without-scrollbar;

    height: 100%;
    max-height: 100%;

    overflow: scroll;
    scroll-behavior: smooth;

    @include hide-scrollbar;
  }

  &__images-wrapper-cover {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: $level2;
  }

  &__image {
    position: relative;

    margin-right: $scroll-width;

    flex-basis: $width-without-scrollbar;
    height: 100%;

    overflow: hidden;

    &__img {
      position: absolute;

      filter: opacity(50%) blur(2px);

      @include image-sizing;
      @include center-x-absolute;
    }

    &__text {
      position: absolute;
      bottom: $margin-smallest;
      right: $margin-smallest;

      font-family: $header-font;
      font-size: $header-medium;
      text-align: right;
      color: $color1-mid;

      padding: 0 $padding-small;

      width: 100%;
      max-width: 80rem;

      z-index: $level5;

      @media (max-width: $breakpoint-tablet) {
        text-align: center;
        right: initial;
        left: 50%;

        margin-right: -50%;

        transform: translateX(-50%);
      }

      @media (max-width: $breakpoint-phone) {
        font-size: $text-large;
      }
    }
  }

  &__section-one {
    display: grid;
    justify-content: center;

    padding: $padding-largest 0;

    &__wrapper {
      margin: auto;
      padding: 0 $padding-smallest;

      width: 100%;
    }

    &__text {
      font-size: $text-large;
      text-align: center;

      margin-bottom: $margin-mid;

      width: 100%;
      max-width: 80rem;

      @media (max-width: $breakpoint-phone) {
        font-size: $text-medium;

        margin-bottom: $margin-small;
      }
    }

    &__button-wrapper {
      display: flex;
      justify-content: center;

      width: 100%;
    }
  }

  &__section-two {
    display: grid;
    justify-content: center;

    padding: $padding-largest 0;

    background: $color2-light;
    box-shadow: inset 0 5px 20px $color2-dark;

    &__wrapper {
      margin: auto;
      padding: 0 $padding-smallest;

      width: 100%;
    }

    &__text {
      font-size: $header-medium;
      text-align: center;

      margin-bottom: $margin-large;

      width: 100%;

      @media (max-width: $breakpoint-phone) {
        font-size: $header-small;

        margin-bottom: $margin-small;
      }
    }

    &__icon-container {
      display: grid;
      justify-content: center;

      grid-template-columns: 20rem 20rem 20rem 20rem 20rem;

      @media (max-width: $breakpoint-desktop) {
        grid-template-columns: 15rem 15rem 15rem 15rem 15rem;
      }

      @media (max-width: $breakpoint-tablet) {
        grid-template-columns: 20rem;
      }
    }

    &__icon-wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      width: 100%;

      &:not(:last-child) {
        @media (max-width: $breakpoint-tablet) {
          border-bottom: 1px solid $border-color-dark;

          margin-bottom: $margin-mid;
        }
      }
      @media (max-width: $breakpoint-tablet) {
      padding-bottom: $padding-small;
    }
    }

    &__icon {
      flex-basis: 100%;

      font-size: $header-small;
      color: $color2-dark;
      text-align: center;

      margin-bottom: $margin-small;

      @media (max-width: $breakpoint-desktop) {
        font-size: $text-large;
      }

      @media (max-width: $breakpoint-tablet) {
        font-size: $header-small;
      }
    }

    &__icon-text {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-basis: 100%;

      text-align: center;
      font-size: $text-medium;

      max-width: 13rem;

      @media (max-width: $breakpoint-desktop) {
        font-size: $text-small;
      }

      @media (max-width: $breakpoint-tablet) {
        font-size: $text-medium;
      }
    }
  }

  &__section-three {
    position: relative;
    display: grid;
    justify-content: center;

    padding: $padding-largest 0;
    overflow: hidden;

    &__background {
      position: absolute;

      filter: opacity(50%);

      @include image-sizing;
      @include center-absolute;
    }

    &__wrapper {
      margin: auto;
      padding: 0 $padding-smallest;

      width: 100%;

      z-index: $level5;
    }

    &__text {
      font-size: $text-large;
      text-align: center;

      margin-bottom: $margin-mid;

      width: 100%;
      max-width: 80rem;

      @media (max-width: $breakpoint-phone) {
        font-size: $text-medium;

        margin-bottom: $margin-small;
      }
    }

    &__button-wrapper {
      display: flex;
      justify-content: center;

      width: 100%;
    }
  }
}