@import "../../variables";

.cookie-accepted {
  &__background {
    position: fixed;
    top: 0;
    left: 0;

    height: 100vh;
    width: 100vw;

    z-index: $level2 + 8 ;
    background-color: rgba($color2-light, 0.5);
    backdrop-filter: blur(5px);
  }

  &__box {
    position: fixed;
    bottom: 0;
    left: 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    height: fit-content;
    min-height: 20rem;
    width: 100vw;

    padding: $padding-small $padding-smallest;

    z-index: $level2 + 9 ;
    background-color: $color2-lightest;
    backdrop-filter: blur(5px);
  }

  &__text {
    flex-basis: 100%;

    font-size: $text-medium;

    margin-bottom: $margin-small;
  }
}