@import "../../variables";
@import "../../mixins";

.contact {
  position: relative;

  display: grid;

  overflow: hidden;

  &__image-wrapper {
    overflow: hidden;
    @include initial-image-container;
    max-height: 30rem;
  }

  &__background-image {
    position: absolute;

    filter: opacity(50%);

    @include image-sizing;
    @include center-absolute;
  }

  &__content-wrapper {
    display: flex;
    flex-wrap: wrap;

    padding: $padding-large $padding-mid $padding-largest;

    width: 100%;
    height: fit-content;

    z-index: $level5;
    background: $color2-lightest;
    box-shadow: 0 5px 20px $color2-dark;

    @media (max-width: $breakpoint-tablet) {
      padding: $padding-large $padding-smallest $padding-largest;
    }
  }

  &__header-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-basis: 40%;

    @media (max-width: $breakpoint-tablet) {
      flex-basis: 100%;
      justify-content: center;
    }
  }

  &__dummy-header-wrapper {
    flex-basis: 60%;
  }

  &__header {
    font-size: $header-medium;
    letter-spacing: $letter-spacing-small;
    font-family: $header-font;
    color: $color1-dark;

    margin-bottom: $margin-small;
    padding: 0 $padding-small $padding-smallest 0;

    width: fit-content;

    @media (max-width: $breakpoint-desktop) {
      font-size: $header-small;
    }

    @media (max-width: $breakpoint-tablet) {
      width: 100%;

      text-align: center;
      padding: 0 $padding-smallest $padding-smallest;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-basis: 100%;
  }

  &__form-container {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    flex-basis: 40%;
    padding-right: $padding-small;
    border-right: 1px solid $color2-dark;

    @media (max-width: $breakpoint-tablet) {
      border-right: none;
      justify-content: center;
      border-bottom: 1px solid $color2-dark;
      flex-basis: 100%;
      padding-right: 0;
      padding-bottom: $padding-small;
    }
  }

  &__form-wrapper {
    flex-basis: 50rem;
    max-width: 50rem;

    margin-bottom: $margin-small;

    @media (max-width: $breakpoint-tablet) {
      flex-basis: 100%;
    }
  }

  &__form-button-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-basis: 100%;

    @media (max-width: $breakpoint-tablet) {
      justify-content: center;
    }
  }

  &__address-wrapper {
    flex-basis: 60%;

    padding-left: $padding-small;

    @media (max-width: $breakpoint-tablet) {
      flex-basis: 100%;
      max-width: 40rem;
      padding-left: 0;
      padding-top: $padding-small;
    }
  }

  &__address-map {
    width: 100%;
    height: 40rem;

    margin-bottom: $margin-small;

    border: 1px solid $color2-light;

    box-shadow: 0 5px 20px $color2-light;
  }

  &__address-phone-wrapper {
    display: flex;
  }

  &__address {
    flex-basis: 50%;
  }

  &__address-header {
    font-size: $text-large;
    letter-spacing: $letter-spacing-small;
    margin-bottom: $margin-smallest;
    color: $color1-dark;
  }

  &__address-detail {
    font-size: $text-small;

    &:not(:last-child) {
      margin-bottom: $margin-smallest;
    }
  }

  &__phone {
    flex-basis: 50%;
  }
}
