@import "../../variables";

.disclaimer {
  text-align: justify;

  &__header {
    font-size: $header-small;
    font-weight: bold;
    font-family: $header-font;
    text-align: center;

    margin-bottom: $margin-small;

    width: 100%;
  }

  &__text {
    font-size: $text-small;
    margin-bottom: $margin-smallest;
  }
}